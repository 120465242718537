.social-links {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	-webkit-padding-start: 0;
}

.App-header .social-links {
	position: absolute;
	right: 20px;
	top: 20px;
}

.social-icon {
	list-style: none;
	margin: 0 10px;
}

.social-icon .github {
	color: #fff;
}

@media screen and (max-width: 768px) {
  /* .App-header .social-links {
    position: relative;
    right: initial;
    top: initial;
  } */

  .social-links {
    justify-content: center;
  }
}
