.App-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #222;
  height: 50px;
  padding: 10px 20px;
  color: white;
}

.App-footer .contact-info a {
  color: #fff;
}

.App-footer .contact-info a:hover {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .project-header::after {
    transform: none;
    right: -70px;
  }

  .App-footer {
    height: initial;
    justify-content: space-evenly;
  }

  .App-footer .contact-info {
    /* width: 100%; */
    display: none;
  }

  .App-footer .divider {
    display: none;
  }

  .App-footer .copyright {
    display: block;
  }

  .App-footer .social-links {
    /* display: none; */
    width: 100%;
    margin: 0;
  }

  .App-footer .fas, .App-footer .fab {
    display: inline-block;
  }

}
