.App {
  text-align: center;
}

.App:last-child {
  margin-bottom: 70px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.porfolio-wrapper {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .App:last-child {
    margin-bottom: 44px;
  }
}
