/* About Page */

.about-page {
  text-align: left;
  margin: 40px auto;
}

.about-page > div > h3 {
  text-align: center;
  margin-top: 80px;
}

.about-bio {
  display: flex;
}

.about-bio .mobile-intro-line {
  display: none;
}

.about-bio .bio-photo {
  position: relative;
  margin-right: 30px;
}

.about-bio .bio-photo img {
  width: 300px;
  border-radius: 50%;
  box-shadow: 5px 5px 2px rgba(0,0,0,0.3);
}

.about-skills .skillset-1 {
  text-align: center;
  max-width: 700px;
  margin: 8px auto;
}

.skill-icon {
  position: relative;
  cursor: pointer;
  width: 84px;
  height: 84px;
  display: inline-block;
  margin: 8px 8px 15px;
  border-radius: 10px;
  transition: all 500ms ease-in;
  background: none;
  border: none;
}

button.skill-icon:focus {
  outline: 0;
}

.skill-icon:focus::after,
.skill-icon:hover::after {
  content: attr(data-title);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%,0);
  color: #fff;
  z-index: 1;
}

.skill-icon img {
  transition: all 250ms ease-in;
}

.skill-icon:hover img,
.skill-icon:focus img {
  transform: scale(1.2);
}

.skill-info {
  width: 80%;
  height: auto;
  min-height: 40%;
  margin: 30px auto 0;
  background-color: #ddd;
  border-radius: 5px;
  color: #000;
  text-align: center;
  padding: 30px;
}

.featured {
  text-align: left;
}

/* Mobile, Tablets */
@media screen and (max-width: 768px) {
  .container {
    padding: 0;
  }

  .about-page {
    text-align: center;
    margin-top: 20px;
  }

  .about-page > * {
    padding: 20px;
  }

  .about-page > div > h3 {
    text-align: center;
    margin-top: 0;
  }

  .about-bio {
    flex-flow: column;
    justify-content: center;
  }

  .about-bio .mobile-intro-line {
    display: block;
  }

  .about-bio .bio-photo img {
    width: 200px;
    width: 70%;
    border-radius: 0;
    padding: 8px;
    border: 1px solid #00f;
  }

  .about-bio .bio-photo {
    margin: 0;
    margin-bottom: 20px;
  }

  .about-bio .bio-text {
    margin: 20px 20px 50px;
    text-align: left;
  }

  .about-skills {
    text-align: left;
    background-color: rgb(4, 2, 4);
    color: #bbb;
    padding: 20px 0;
    min-height: 100vh;
    height: auto;
  }

  .skill-icon img {
    padding: 8px;
  }

  .skill-icon:hover,
  .skill-icon:focus {
    background-color: #ccc;
  }

  .intro-line {
    display: none;
  }

  .about-projects {
    background-color: #d1d5e6;
  }

  .about-projects .row {
    margin: 0;
  }
}

/* Laptops & Desktops */
@media screen and (min-width: 769px) {
  .skill-icon {
    margin: 15px;
  }

  .about-projects .link-to-more {
    text-align: center;
  }

  .skill-icon:focus::after,
  .skill-icon:hover::after {
    color: #000;
  }

  /* .skill-info {
    display: none;
  } */

  .about-bio .bio-text {
    padding: 13% 20px;
  }
}

@media screen and (max-width: 992px) {
  .about-skills {
    margin-top: 30px;
  }
}
