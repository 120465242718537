.nav {
  background-color: rgba(153, 153, 255, 1);
}

.nav-tabs {
	justify-content: space-around;
}

.nav-tabs-header {
  display: flex;
  justify-content: space-between;
}

.nav-tabs .nav-item {
	flex-grow: 1;
}

.nav-tabs .nav-link {
  color: #000;
  border-right: 1px solid #a8b0c5;
}

.App .nav-tabs .nav-link:hover {
  border-right: 1px solid #a8b0c5;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link.active {
  border-color: transparent;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .nav-tabs {
    margin-top: 33px;
  }
}
