.project-item {
	position: relative;
	max-width: 400px;
	width: 80%;
	margin: 20px;
	border-radius: 5px;
	box-shadow: 2px 2px 8px rgba(0,0,0,0.2);
	transition: all 500ms ease;
	border: 1px solid #d3d3d3;
}

.project-item.highlight {
	box-shadow: 0 0 5px 5px rgba(37, 38, 176, 0.5);
}

.project-item.dim {
	opacity: .5;
}

.project-item:hover {
  transform: translateY(-1px) scale(1.01);
  box-shadow: 0 0 20px rgba(0,0,0,0.2), 5px 0 20px rgba(0,0,0,0.4), -5px 0 20px rgba(0,0,0,0.4);
}

.project-item:hover .project-details {
	opacity: 1;
	background-color: rgba(0,0,0,.7);
	color: #fff;
}

.project-item > a {
	color: #000;
}

.project-item:hover > a {
	color: #000;
	text-decoration: none;
}

.project-item:hover .site-link {
	top: 10%;
	right: -43px;
	opacity: 1;
}

.project-item:hover .code-link {
	top: 30%;
	right: -43px;
	opacity: 1;
}

.project-image {
	width: 100%;
    height: 200px;
}

.project-details {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 100%;
	height: 100%;
	transition: all 750ms ease;
	text-align: center;
	opacity: 0;
	padding: 20px;
}

.site-link {
    position: absolute;
    right: 10%;
    top: 30%;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: rgba(0,0,0,.6);
    transition: top 500ms .5s ease, right 500ms .5s ease, opacity 500ms .5s ease;
    opacity: 0;
}

.site-link a {
    color: #fff;
    padding: 0.5rem;
    line-height: 1.875rem;
}

.code-link {
    position: absolute;
    top: 30%;
    right: 10%;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #fff;
    transition: top 500ms .5s ease, right 500ms .5s ease, opacity 500ms .5s ease;
    opacity: 0;
}

.code-link a {
	color: rgba(0,0,0,.6);
}

.site-link:hover {
	background-color: #007bff;
}

.code-link:hover a {
	color: #007bff;
}

.desc {
	display: none;
}

@media screen and (max-width: 768px) {
	.project-details {
		position: relative;
    opacity: 1;
    top: 0;
    left: 0;
    height: initial;
    transform: none;
    border-top: 1px solid #d3d3d3;
		background-color: #fff;
	}
	.project-details div {
		text-align: left;
	}

	.project-item:hover .project-details {
		background-color: #fff;
		color: #000;
	}

	.links {
		display: flex;
		justify-content: space-around;
		padding: 5px;
		background-color: #fff;
	}

	.site-link, .code-link {
		position: static;
		opacity: 1;
		width: 40%;
		border-radius: 0;
		background-color: #007bff;
		border-radius: 5px;
	}
	.site-link a, .code-link a {
		color: #fff;
		padding: 0;
		text-align: center;
	}

	.site-link:hover, .code-link:hover {
		box-shadow: 2px 2px 10px rgba(0,0,0,.6);
	}

	.site-link:hover a, .code-link:hover a{
		color: #fff;
		text-decoration: none;
	}

	.fas, .fab {
		display: none;
	}

	.desc {
		display: block;
	}
}
